































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { State, Action } from 'vuex-class';
import { RootState } from '@/store/store';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  Product,
  ApplicationState
} from '@/store/modules/application/types/application.types';
import { get as _get } from 'lodash';
import Container from '@/components/Container.vue';
import SingleProduct from './components/SingleProduct.vue';
import MultipleProducts from './components/MultipleProducts.vue';
import {
  DialogProgrammatic as Dialog,
  ToastProgrammatic as Toast
} from 'buefy';
import { AxiosError, AxiosResponse } from 'axios';
import { getErrorMsg } from '@/utils/errors.util';
import { CreateOrderPayload } from '@/store/modules/order';
import { UserSubscription } from '../../store/modules/subscription/subscription.state';
import { WkSubscriptionStatus } from '@/jbi-shared/types/sumari.types';
import { JAAS_APP } from '../../jbi-shared/types/jaas-app.types';
import BaseLoading from '@/components/base/BaseLoading.vue';

@Component({
  components: {
    Container,
    SingleProduct,
    MultipleProducts,
    BaseLoading
  }
})
export default class ProductPage extends Vue {
  @Prop({ type: Boolean, default: false })
  public isLoading!: boolean;

  @Prop({ default: 'ProductPage' })
  public readonly title!: string;

  @Action('application/getProducts')
  public getProducts!: ({
    appId,
    productId,
    token
  }: {
    appId?: string;
    productId?: string;
    token?: string;
  }) => Promise<void>;

  @Action('order/createOrder')
  public createOrder!: (payload: CreateOrderPayload) => Promise<AxiosResponse>;

  @Action('sumari/getWkSubscription')
  public checkWkSubscription!: () => Promise<AxiosResponse>;

  @Action('subscription/getMySubscriptions')
  public getMySubscriptions!: () => void;

  @State('subscriptions', {
    namespace: 'subscription'
  })
  public subscriptions!: UserSubscription[];

  @State((state) => state.apiState.getMySubscriptions.loading, {
    namespace: 'subscription'
  })
  public getMySubscriptionsLoading!: boolean;

  @State((state: RootState) => state.application.apiState.getProducts.loading)
  public getProductsLoading!: boolean;

  get products(): Product[] {
    const products =
      (this.$store.state as RootState).application.products || [];

    return products.filter((res) => !!res && res.isActive && !res.isDeleted);
  }

  get getProductsState() {
    return (this.$store.state.application as ApplicationState).apiState
      .getProducts;
  }

  get getWkSubscriptionStatus() {
    return (this.$store.state as RootState).sumari.wkSubscription;
  }

  get productsCount() {
    if (this.products) {
      return Array.isArray(this.products) ? this.products.length : 0;
    }
  }

  get isSingleProduct(): boolean | undefined {
    if (this.products) {
      return Array.isArray(this.products) ? this.products.length === 1 : false;
    }
  }

  public mounted() {
    const { product_id, token } = this.$route.query;
    const { appId } = this.$route.params;

    this.getProducts({
      appId: appId ? appId + '' : undefined,
      productId: product_id ? product_id + '' : undefined,
      token: token ? token + '' : undefined
    });

    if (!this.subscriptions) {
      this.getMySubscriptions();
    }
    this.checkWkSubscription();
  }

  get userSubscriptions() {
    if (Array.isArray(this.subscriptions)) {
      return this.subscriptions;
    }
    return false;
  }

  public handlePurchase(product: Product) {
    if (product) {
      if (
        this.getWkSubscriptionStatus.status === WkSubscriptionStatus.ACTIVE &&
        product.application.id === JAAS_APP.sumari
      ) {
        return Dialog.alert({
          title: `Existing Ovid SUMARI subscription`,
          message: `<p class="subtitle">You already have active Ovid subscription for SUMARI <p>`,
          confirmText: 'Go Back',
          type: 'is-primary'
        });
      } else if (
        this.getWkSubscriptionStatus.status === WkSubscriptionStatus.EXPIRED &&
        product.application.id === JAAS_APP.sumari
      ) {
        Dialog.alert({
          title: `Expired Ovid SUMARI subscription`,
          message: `<p class="subtitle">Your current subscription for SUMARI via Ovid is expired.
                    If you click on Proceed, you account will transfered to MYJBI and
                    you won't be able to login into SUMARI via Ovid anymore.
                    You will still be able to access all your project in SUMARI via MYJBI Login <p>`,
          confirmText: 'Proceed',
          type: 'is-primary',
          onConfirm: () => {
            this.handleOrderCreation(product);
          }
        });
      } else {
        this.handleOrderCreation(product);
      }
    }
  }

  public handleCheckout({
    productId,
    applicationId
  }: {
    productId: number;
    applicationId: JAAS_APP;
  }) {
    if (
      this.getWkSubscriptionStatus.status === WkSubscriptionStatus.ACTIVE &&
      applicationId === JAAS_APP.sumari
    ) {
      return Dialog.alert({
        title: `Existing Ovid SUMARI subscription`,
        message: `<p class="subtitle">You already have active Ovid subscription for SUMARI <p>`,
        confirmText: 'Go Back',
        type: 'is-primary'
      });
    } else if (
      this.getWkSubscriptionStatus.status === WkSubscriptionStatus.EXPIRED &&
      applicationId === JAAS_APP.sumari
    ) {
      Dialog.alert({
        title: `Expired Ovid SUMARI subscription`,
        message: `<p class="subtitle">Your current subscription for SUMARI via Ovid is expired.
                    If you click on Proceed, you account will transfered to MYJBI and
                    you won't be able to login into SUMARI via Ovid anymore.
                    You will still be able to access all your project in SUMARI via MYJBI Login <p>`,
        confirmText: 'Proceed',
        type: 'is-primary',
        onConfirm: () =>
          this.$router.push({
            name: 'Checkout',
            params: { productId: productId.toString() }
          })
      });
    } else {
      return this.$router.push({
        name: 'Checkout',
        params: { productId: productId.toString() }
      });
    }
  }

  public async handleOrderCreation(product: Product) {
    if (typeof product !== 'undefined') {
      const response = await this.createOrder({
        productId: product.id,
        billingAddress: null
      });

      const { application } = product;
      const redirectURL =
        application.id === JAAS_APP.paces
          ? process.env.VUE_APP_PACES_FRONTEND_URL
          : application.id === JAAS_APP.sumari
          ? process.env.VUE_APP_SUMARI_URL
          : '';

      if (_get(response, 'data.paymentRequired')) {
        const responseCode = _get(response, 'data.APIResponse.ResponseCode');
        const responseText = _get(response, 'data.APIResponse.ResponseText');

        if (responseCode === 0) {
          const authKey = _get(response, 'data.AuthKey');
          return (window.location.href = `${process.env.VUE_APP_BPOINT_REDIRECT_PAYMENT_URL}?in_pay_token=${authKey}`);
        }

        return Dialog.alert({
          title: `Error code ${responseCode}`,
          message: `<p class="subtitle"> "${responseText}"!<p>`,
          confirmText: 'Try again',
          type: 'is-primary'
        });
      }

      return Dialog.confirm({
        title: 'Redemption Successful',
        message: `<p class="subtitle">You have successfully redeemed '${product.name}'. <p>`,
        cancelText: 'Close',
        confirmText: `Launch ${application.name}`,
        type: 'is-primary',
        onConfirm: () => {
          window.location.href = `${redirectURL}`;
        },
        onCancel: () => {
          this.getProductList();
        }
      });
    }
  }

  public async getProductList() {
    const { product_id, token } = this.$route.query;
    const { appId } = this.$route.params;

    await this.getProducts({
      appId: appId ? appId + '' : undefined,
      productId: product_id ? product_id + '' : undefined,
      token: token ? token + '' : undefined
    });
  }

  @isDifferent
  @isTruthy
  @Watch('getProductsState.error')
  public onError(e: AxiosError) {
    const status = _get(e, 'response.status') as number;
    const isForbidden = status === 403;
    if (!isForbidden) {
      Dialog.alert({
        message: getErrorMsg(e),
        onConfirm: () => {
          this.$router.replace({
            name: 'Application'
          });
          window.location.reload();
        }
      });
    }
  }
}
