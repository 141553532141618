export interface ConvertInternalUserToMyJbiRequest {
  email: string;
  username: string;
  expiryDate: Date;
  firstName: string;
  lastName: string;
}

export interface WkSubscriptionCheckResponse {
  status: WkSubscriptionStatus;
}

export enum WkSubscriptionStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  INACTIVE = 'INACTIVE',
  INVALID_EMAIL = 'INVALID_EMAIL',
  NOT_EXTERNAL_USER = 'NOT_EXTERNAL_USER',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
}
