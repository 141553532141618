import { AxiosError } from 'axios';
import { get as _get } from 'lodash';

export const getErrorMsg = (e: AxiosError | Error) => {
  return (
    _get(e, 'response.data.details[0].message') ||
    _get(e, 'response.data.message') ||
    e.message
  );
};
