






































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import {
  Product,
  Application
} from '@/store/modules/application/types/application.types';
import dayjs from 'dayjs';
import { moneyParser } from '../../../utils/parser.util';
import { UserSubscription } from '../../../store/modules/subscription/subscription.state';
import { get as _get } from 'lodash';
import { JAAS_APP } from '@/jbi-shared/types/jaas-app.types';
import SingleProductDetails from './SingleProductDetails.vue';

@Component({
  components: {
    SingleProductDetails
  }
})
export default class SingleProduct extends Vue {
  @Prop({ type: Boolean, default: false })
  public isLoading!: boolean;

  @Prop() public products!: Product[];

  @Prop() public subscriptions!: UserSubscription[];

  public navigateToCheckoutPage(productId: number) {
    return this.$router.push({
      name: 'Checkout',
      params: { productId: productId.toString() }
    });
  }

  public checkProductPrice(product: Product) {
    return (product.currentPrice.amount / 100).toFixed(2) === '0.00';
  }

  get dayjs() {
    return dayjs;
  }

  get jaasApplicationId() {
    return JAAS_APP;
  }

  get checkProductStatus() {
    if (
      this.products[0].redeemable &&
      Math.sign(this.products[0].redeemable) === -1
    ) {
      return false;
    }
    return true;
  }

  get activeSubscription(): boolean {
    const appId = _get(this.products, '0.applicationId');
    if (Array.isArray(this.subscriptions)) {
      return this.subscriptions.some((o) => o.applicationId === appId);
    }
    return false;
  }

  get expiryDate() {
    const appId = _get(this.products, '0.applicationId');
    if (!this.subscriptions) {
      return;
    }
    const sumari = this.subscriptions.find((o) => o.applicationId === appId);
    if (sumari) {
      return dayjs(sumari.endedAt).format('YYYY-MM-DD');
    }
    return;
  }
}
