
























import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import {
  Product,
  Application
} from '@/store/modules/application/types/application.types';
import { get as _get } from 'lodash';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import ProductCard from './ProductCard.vue';
import MultipleProductDetails from './MultipleProductDetails.vue';
import { UserSubscription } from '../../../store/modules/subscription/subscription.state';

@Component({
  components: {
    ProductCard,
    BaseModal,
    MultipleProductDetails
  }
})
export default class MultipleProducts extends Vue {
  @Prop({ type: Boolean, default: false })
  public isLoading!: boolean;

  @Prop() public products!: Product[] | undefined;

  @Prop() public subscriptions!: UserSubscription[];

  get applicationId() {
    const { appId } = this.$route.params;
    return appId;
  }

  get activeSubscription(): boolean {
    const appId = _get(this.products, '0.applicationId');
    if (Array.isArray(this.subscriptions)) {
      return this.subscriptions.some((o) => o.applicationId === appId);
    }
    return false;
  }
}
