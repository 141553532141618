









































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { Product } from '@/store/modules/application/types/application.types';
import { moneyParser } from '@/utils/parser.util';
import {
  JAAS_APP,
  JAAS_APP_PRODUCT_DETAILS
} from '@/jbi-shared/types/jaas-app.types';

@Component({})
export default class SingleProductDetails extends Vue {
  @Prop() public product!: Product;

  public displayPrice({
    currency,
    amount
  }: {
    currency: string;
    amount: number;
  }) {
    if (amount === 0) {
      return 'FREE';
    }
    return moneyParser({
      currency,
      value: amount
    });
  }

  public displayConvertedPrice({
    currency,
    amount
  }: {
    currency: string;
    amount: number;
  }) {
    if (amount === 0) {
      return '';
    }
    return `(${moneyParser({ currency, value: amount })})`;
  }

  public getProductDetails(applicationId: JAAS_APP) {
    if (applicationId) {
      return JAAS_APP_PRODUCT_DETAILS[applicationId].productDetails;
    }
    return '';
  }

  public getProductImage(applicationId: JAAS_APP) {
    if (applicationId === JAAS_APP.paces) {
      return require(`@/assets/images/subscriptions/JBI_PACES-freeproduct_img@2x.png`);
    }

    if (applicationId === JAAS_APP.sumari) {
      return require(`@/assets/images/subscriptions/JBI_SUMARI-freeproduct_img@2x.png`);
    }

    if (applicationId === JAAS_APP.training) {
      return require(`@/assets/images/subscriptions/jbitraining_thumbnail_img@2x.png`);
    }
    return '';
  }

  public getProductLogo(applicationId: JAAS_APP) {
    if (applicationId === JAAS_APP.paces) {
      return require(`@/jbi-shared/assets/images/logo_paces@2x.png`);
    }

    if (applicationId === JAAS_APP.sumari) {
      return require(`@/jbi-shared/assets/images/jbisumari_card_img.png`);
    }

    if (applicationId === JAAS_APP.training) {
      return require(`@/jbi-shared/assets/images/jbitraining_card_img.png`);
    }
    return '';
  }

  public replaceUnderscoreToReadableString(dataString: string) {
    return dataString.replace(/_/g, ' ');
  }
}
