























































import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { State, Getter, Action, Mutation, namespace } from 'vuex-class';
import { moneyParser } from '@/utils/parser.util';
import { JAAS_APP } from '@/jbi-shared/types/jaas-app.types';

@Component({
  components: {}
})
export default class ProductCard extends Vue {
  @Prop() public productId!: number;
  @Prop() public name!: string;
  @Prop() public applicationId!: JAAS_APP;
  @Prop() public description!: string;
  @Prop() public licenseModels!: [];
  @Prop() public redeemable!: number;
  @Prop() public currentPrice!: {
    currency: string;
    amount: number;
  };
  @Prop() public convertedPrice!: {
    currency: string;
    amount: number;
  };
  @Prop() public chargeDetail!: {
    gstAmount: number;
    netPrice: number;
  };
  @Prop() public isSubscribed!: boolean;

  get displayPrice() {
    if (this.chargeDetail.netPrice === 0) {
      return 'FREE';
    }
    return moneyParser({
      currency: this.currentPrice.currency,
      value: this.chargeDetail.netPrice
    });
  }
  get checkProductStatus() {
    if (this.redeemable && Math.sign(this.redeemable) === -1) {
      return false;
    }
    return true;
  }
  get displayConvertedPrice() {
    if (this.convertedPrice && this.convertedPrice.amount !== 0) {
      return `(${moneyParser({
        currency: this.convertedPrice.currency,
        value: this.convertedPrice.amount
      })})`;
    }
    return '';
  }

  public navigateToCheckoutPage(productId: number) {
    return this.$router.push({
      name: 'Checkout',
      params: {
        productId: productId.toString()
      }
    });
  }
}
