















import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { Product } from '@/store/modules/application/types/application.types';
import {
  JAAS_APP,
  JAAS_APP_PRODUCT_DETAILS
} from '@/jbi-shared/types/jaas-app.types';

@Component({})
export default class SingleProductDetails extends Vue {
  @Prop() public products!: Product[] | undefined;

  @Prop() public applicationId!: JAAS_APP;

  public getProductDescription(applicationId: JAAS_APP) {
    if (applicationId) {
      return JAAS_APP_PRODUCT_DETAILS[applicationId].productDescription;
    }
    return '';
  }

  public getProductImage(applicationId: JAAS_APP) {
    if (applicationId === JAAS_APP.paces) {
      return require(`@/assets/images/subscriptions/JBI_PACES-product_img@2x.png`);
    }

    if (applicationId === JAAS_APP.sumari) {
      return require(`@/assets/images/subscriptions/JBI_SUMARI-product_img@2x.png`);
    }

    if (applicationId === JAAS_APP.training) {
      return require(`@/assets/images/subscriptions/jbitraining_thumbnail_img@2x.png`);
    }
    return '';
  }

  public getProductLogo(applicationId: JAAS_APP) {
    if (applicationId === JAAS_APP.paces) {
      return require(`@/jbi-shared/assets/images/logo_paces@2x.png`);
    }

    if (applicationId === JAAS_APP.sumari) {
      return require(`@/jbi-shared/assets/images/jbisumari_card_img.png`);
    }

    if (applicationId === JAAS_APP.training) {
      return require(`@/jbi-shared/assets/images/jbitraining_card_img.png`);
    }
    return '';
  }
}
